import { memo } from "react";

import BB_ProductCell from "@/Components/BB_ProductCell";
import BB_Product from "@/types/BB_Product";

export type BB_CompactProductsListProps = {
	products: BB_Product[];
	direction?: "row" | "column";
};

const BB_CompactProductsList = (props: BB_CompactProductsListProps) => {
	return (
		<div
			className={
				props.direction === "column"
					? "col flex flex-col gap-4"
					: "grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-5"
			}>
			{props.products.map((product, index) => (
				<BB_ProductCell
					key={"compact_" + product.id + "_" + index.toString()}
					index={index}
					product={product}
					isCompact={props.direction === "column"}
				/>
			))}
		</div>
	);
};

export default memo(BB_CompactProductsList);
